import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContent } from 'react-toastify';

import CampaignItem from '../../components/CampaignItem/CampaignItem';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { trackFormSubmit } from '../../constants/analytics';
import { FORMS_ENUM } from '../../constants/shipping';
import useAnalytics from '../../hooks/useAnalytics';
import { TShippingFormValues } from '../../types/shipping';
import { AddressChoiceEnum } from '../../types/ui';
import { EMPTY_ADDRESS } from '../../util/address';
import { mapAddressInfo, mapContactInfo } from '../../util/form';
import { getShippingStepValidation } from '../../util/validation';
import AddressFormContainer from './AddressFormContainer';
import ContactFormContainer from './ContactFormContainer';

import { updateRequestAction } from '../../store/actions/form';
import { selectMappedPickedItems, selectPickedCampaign } from '../../store/selectors/derived';
import { selectInitialAddress, selectIsDigital } from '../../store/selectors/init';
import { TInitAddress } from '../../types/api';
import { IError, ISubmitAddressPayload } from '../../types/store';

import styles from './ShippingForm.module.scss';

const ShippingContainer = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();

  const isDigital = useSelector(selectIsDigital);
  const initialAddress = useSelector(selectInitialAddress);
  const items = useSelector(selectMappedPickedItems);
  const selectedCampaign = useSelector(selectPickedCampaign);

  const { fixedAddress, addressBook, initialValues } = React.useMemo(() => {
    const { fixed_address, address_book } = initialAddress || ({} as TInitAddress);

    const isFixedAddress = !!fixed_address?.length;
    const hasAnyAddress = !!(fixed_address?.length || address_book?.length);

    const contactValues = mapContactInfo(initialAddress);
    const addressValues = (() => {
      const nothing = { mode: undefined, picked_address_id: undefined, userAddress: undefined };

      switch (true) {
        case isDigital:
          return nothing;
        case isFixedAddress && fixed_address.length === 1:
          return nothing;
        case isFixedAddress && fixed_address.length > 1:
          return { mode: undefined, userAddress: undefined, picked_address_id: '' };
        case !isFixedAddress && hasAnyAddress:
          return {
            mode: AddressChoiceEnum.Nothing,
            picked_address_id: address_book?.length === 1 ? address_book[0].uid : '',
            userAddress: {
              ...EMPTY_ADDRESS,
              ...mapAddressInfo(initialAddress),
            },
          };
        case !isFixedAddress && !hasAnyAddress:
          return {
            mode: undefined,
            picked_address_id: undefined,
            userAddress: {
              ...EMPTY_ADDRESS,
              ...mapAddressInfo(initialAddress),
            },
          };
        default:
          console.warn('Unreachable default case in getting initialValues in ShippingContainer');
          return nothing;
      }
    })();

    const initValues: TShippingFormValues = {
      ...contactValues,
      ...addressValues,
    };

    return {
      fixedAddress: fixed_address,
      addressBook: address_book,
      initialValues: initValues,
    };
  }, [initialAddress, isDigital]);

  const validationSchema = React.useMemo(
    () =>
      getShippingStepValidation({
        isDigital,
        numberOfOptions: (fixedAddress || addressBook || []).length,
        isFixed: !!fixedAddress?.length,
      }),
    [isDigital, fixedAddress, addressBook],
  );

  const handleSubmit: (values: TShippingFormValues, actions: FormikHelpers<TShippingFormValues>) => Promise<void> =
    React.useCallback(
      async (userInput, actions) => {
        await new Promise((resolve, reject) => {
          const { mode, userAddress, picked_address_id, ...contact } = userInput || {};

          if (!validationSchema.isValidSync(userInput)) {
            reject(new Error('Shipping validation error'));
            console.warn('Unreachable scenario. Please, contact administrator');
          }

          let values: ISubmitAddressPayload['values'] | undefined;

          switch (true) {
            // "Digital" or "Fixed"
            // There's no option to change the address => no need to send the address
            case isDigital || (fixedAddress && fixedAddress.length === 1): {
              values = {
                ...contact,
              };
              break;
            }
            // "Recipient decides from list only"
            // Fixed Address with multiple options to choose from
            // Address has been picked from the Address Book dropdown
            case fixedAddress && fixedAddress.length > 1: {
              values = {
                ...contact,
                picked_address_id,
              };
              break;
            }
            // "Recipient decides" with non-zero amount of the Address Book options
            // Recipient opt-in to decide from list
            // Address has been picked from the Address Book dropdown
            case mode === AddressChoiceEnum.Select: {
              values = {
                ...contact,
                picked_address_id,
              };
              break;
            }
            // "Recipient decides" with non-zero amount of the Address Book options
            // Recipient opt-in to enter address via shipping form
            case mode === AddressChoiceEnum.Form: {
              values = {
                ...contact,
                ...userAddress,
              };
              break;
            }
            default: {
              // "Recipient decides" and no Address Book options
              // Default scenario - regular shipping of physical gifts
              values = {
                ...contact,
                ...userAddress,
              };
            }
          }

          if (typeof values !== 'undefined') {
            dispatch(updateRequestAction({ values, resolve, reject }));
          } else {
            reject(
              new Error(
                'An error has occurred during the Shipping Address step submission. Please, contact administrator',
              ),
            );
            console.warn(
              "Unreachable scenario - Shipping Form didn't return any values. Please, contact administrator",
            );
          }
        })
          .then(() => {
            actions.setSubmitting(false);
          })
          .catch((e: IError | unknown) => {
            actions.setSubmitting(false);
            const { message } = e as IError;

            if (message) {
              toast.error(<div className={styles.toast} dangerouslySetInnerHTML={{ __html: message }} />);
            } else {
              toast.error(e as ToastContent);
            }
          })
          .then(() => trackEvent(trackFormSubmit(isDigital ? FORMS_ENUM.Digital : FORMS_ENUM.Physical)));
      },
      [dispatch, validationSchema, trackEvent],
    );

  return (
    <Container className={styles.container}>
      <Row className={styles.contentRow}>
        <Col md={12} lg={8} className={styles.formContainer}>
          <Row className="col-12 mb-4 mx-0 flex-center">
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnChange
              validateOnBlur
              validateOnMount
            >
              {({ isSubmitting }) => (
                <Form>
                  <ContactFormContainer />

                  {!isDigital && <AddressFormContainer fixedAddress={fixedAddress} addressBook={addressBook} />}
                  <Col className={styles.actions}>
                    <SubmitButton disabled={isSubmitting}>Confirm</SubmitButton>
                  </Col>
                </Form>
              )}
            </Formik>
          </Row>
        </Col>
        {items.length ? (
          <Col md={12} lg={6} xl={4} className={styles.pickedCampaignContainer}>
            <CampaignItem
              shouldShowCustomizeIcon
              className={styles.pickedCampaign}
              items={items}
              isDigital={isDigital}
              title={selectedCampaign?.name}
            />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default ShippingContainer;
